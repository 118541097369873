import React, { useState, useEffect, useCallback } from 'react';
import Plot from 'react-plotly.js';

function ValvesDashboard({url}) {
  const [data, setData] = useState([]);
  const [detailData, setDetailData] = useState([]);
  const [detailYear, setDetailYear] = useState(null);
  const [nidList, setNidList] = useState([]);
  const [lidList, setLidList] = useState([]);
  const [showNids, setShowNids] = useState(false);
  const [showLids, setShowLids] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        console.log('Fetching data...', url);
        const response = await fetch(url);
        if (response.ok) {
          const jsonResponse = await response.json();
          setData(transformData(jsonResponse));
        } else {
          throw new Error('Request failed!');
        }
      } catch (error) {
        console.error('Error:', error);
      }
    }
    fetchData();
  }, [url]);

  const handleBarClick = useCallback((event) => {
    if (!event.points || event.points.length === 0) return;
    const year = event.points[0].x;
    console.log(`Bar clicked, setting year: ${year}`);
    setDetailData([]);
    setShowLids(false)
    setShowNids(false)
    setDetailYear(year); // This will trigger the useEffect hook
  }, []);

  useEffect(() => {
    let isActive = true; // Flag to manage async operation
    const fetchData = async () => {
      if (!detailYear) return;
      try {
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/search/valves/year/${detailYear}`);
        if (response.ok && isActive) { // Check if component is still mounted
          const jsonResponse = await response.json();
          setDetailData(transformDetailData(jsonResponse));
        }
      } catch (error) {
        console.error('Error fetching details:', error);
      }
    };
    fetchData();
    return () => {
      isActive = false; // Cleanup function to prevent setting state on unmounted component
    };
  }, [detailYear]);

  const transformDetailData = (data) => {
    const nidCount = data.nidList.length
    const lidCount = data.lidList.length
    setNidList(data.nidList)
    setLidList(data.lidList)
    return [{
      values : [nidCount, lidCount],
      labels : ['Todo', 'Done'],
      type: 'pie',
      textinfo: 'label+percent',
      insidetextorientation: 'radial',
    }]
  }

  const transformData = (valves) => {
    const countsByYear = {};
    valves.forEach(valve => {
      const year = new Date(valve.nid).getFullYear();
      if (countsByYear[year]) {
        countsByYear[year]++;
      } else {
        countsByYear[year] = 1;
      }
    });
    const years = Object.keys(countsByYear).sort((a, b) => a - b )
    const counts = years.map(year => countsByYear[year]);
    return [{
      x: years,
      y: counts,
      type: 'bar',
      marker: {
        color: 'blue',
      }
    }]
  }


  const handlePieClick = useCallback((event) => {
    // list valvenames from nidlist and lidlist for the year
    console.log('Pie clicked', event)
    if (event.points[0].label === "Done") {
      setShowNids(true)
      setShowLids(false)
    } else if (event.points[0].label === "Todo") {
      setShowNids(false)
      setShowLids(true)
    } else {
      setShowNids(false)
      setShowLids(false)
    }
  }, [])

  return (
    <div>
      <p>Click a bar to see progress for that year</p>
      <Plot
        data={data}
        layout={{
          width: 920,
          height: 440,
          title: 'Count (nid) coming inspections by year',
          xaxis: {
            title: 'year',
          },
          yaxis: {
            title: 'Count',
          }
        }}
        onClick={handleBarClick}
      />
      {detailData && detailData.length > 0 ? (
        <>
          <Plot
            data={detailData}
            layout={{
              width: 450,
              height: 450,
              title: `Scoop ${detailYear} progress`,
            }}
            onClick={handlePieClick}
          />
          <p>Click a pie slice for item-list</p>
          {showNids && (
            <ul>
                {nidList.map((name, index) => (
                    <li key={index}>{name}</li>
                ))}
            </ul>
          )}
          {showLids && (
              <ul>
                  {lidList.map((name, index) => (
                      <li key={index}>{name}</li>
                  ))}
              </ul>
          )}
        </>
        ):(
          detailYear ? (<p>No detail data for {detailYear}!</p>) : (<p>Click a bar to see progress for that year</p>)
        )}
    </div>
  )
}

export default ValvesDashboard;

// detailYear ? <p>No detail data for {detailYear}!</p>: <p>Click a bar to see progress for that year</p>