// App.js
import React from 'react';
import ValvesDashboard from './ValvesDashboard';
import ReportsDashboard from './ReportsDashboard';
import { useState } from 'react';

function App() {
  const [activeDashboard, setActiveDashboard] = useState('valves');
  const [currentUrl, setCurrentUrl] = useState(process.env.REACT_APP_API_BASE_URL + '/search/valves' );

  const handleDashboardChange = (dashboard) => {
    if (dashboard === 'valves') {
      setCurrentUrl(process.env.REACT_APP_API_BASE_URL + '/search/valves');
    }
    if (dashboard === 'valves prognosis') {
      setCurrentUrl(process.env.REACT_APP_API_BASE_URL + '/search/valvesfuturenid');
    }
    setActiveDashboard(dashboard);
  }

  return (
    <div className="App">
      <h1>Elasticsearch Data Visualization</h1>
      <button onClick={() => handleDashboardChange('valves')} title="Show next inspection count by year">Valves</button>
      <button onClick={() => handleDashboardChange('valves prognosis')} title="Show inspection count prognosis based on current interval for 10 years ahead">Valves prognosis</button>
      <button onClick={() => handleDashboardChange('reports')} title="Show test report count by year">Reports</button>
      {activeDashboard === 'valves' && <ValvesDashboard url={currentUrl}/>}
      {activeDashboard === 'valves prognosis' && <ValvesDashboard url={currentUrl}/>}
      {activeDashboard === 'reports' && <ReportsDashboard />}
    </div>
  );
}

export default App;
