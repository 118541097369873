import React, { useState, useEffect } from 'react';
import Plot from 'react-plotly.js';

function ReportsDashboard() {
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        console.log('Fetching data...');
        const response = await fetch(process.env.REACT_APP_API_BASE_URL + '/search/reports');
        if (response.ok) {
          const jsonResponse = await response.json();
          console.log(jsonResponse);
          setData(transformData(jsonResponse));
        } else {
          throw new Error('Request failed!');
        }
      } catch (error) {
        console.error('Error:', error);
      }
    }
    fetchData();
  }, []);

  const transformData = (reports) => {
    const countsByYear = {};
    reports.forEach(report => {
      const year = new Date(report.reportdate).getFullYear();
      if (countsByYear[year]) {
        countsByYear[year]++;
      } else {
        countsByYear[year] = 1;
      }
    });
    const years = Object.keys(countsByYear).sort((a, b) => a - b )
    const counts = years.map(year => countsByYear[year]);
    return [{
      x: years,
      y: counts,
      type: 'bar',
      marker: {
        color: 'blue',
      }
    }]
  }

  return (
    <Plot
      data={data}
      layout={{
        width: 920,
        height: 440,
        title: 'Valve reports counts by year',
        xaxis: {
          title: 'year',
        },
        yaxis: {
          title: 'Count',
        }
      }}
    />
  )
}

export default ReportsDashboard;